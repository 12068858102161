import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel, CarouselItem, Container, Row } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { RootState } from "../../slice/RootReducer";
import {
  airportDataRequest,
  displayMobileModal,
  updateFieldData,
  updateModalAirportData,
  updateSelectedCardData,
  updateSelectedCardDataMobile,
} from "../../slice/bookingWidgetSlice";
import { config } from "../../../../config/global";
import TGTabs from "../../../../shared/tg-tabs";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import { AirportData, PassengerData } from "../../models/models";
import {
  getCookie,
  getLocalStorageData,
  reverseJsonArray,
  setLocalStorageData,
  updateRecentSearchState,
  validateDepartDate,
} from "../../../../utils/helper";
import TGIcon from "../../../../shared/tg-icon";
import FlightStatus from "./flight-status";
import CheckIn from "./check-in";
import ManageBooking from "./manage-booking/index";
import BookFlight from "./book-flight";
import MultiCity from "./multi-city";
import TGToggle from "../../../../shared/tg-toggle";
import {
  BookFlightAirportDropdown,
  BookFlightTab,
  CabinClass,
  BookFlightTabSections,
} from "../../../../utils/enum-helper";
import moment from "moment";
import styles from "./booking-widget.module.scss";
import {
  getMoreServicesDataFailure,
  getMoreServicesDataSuccess,
} from "../../slice/moreServicesSlice";
import {
  getPromoCodeFail,
  getPromoCodePending,
  getPromoCodeSuccess,
} from "../../slice/promoCode-Slice";
import RedemptionBookingWidget from "../redemption-booking-widget";
import LoginWidget from "../../../../shared/login-widget";
import { fetchProfileRequest } from "../../slice/fetchProfileSlice";
import { fetchNomineeRequest } from "../../slice/fetchNomineeSlice";
import "../../../../i18n";
import TGCustomCarousel from "../../../../shared/tg-custom-carousel";
import { getTripDataFailure, getTripDataSuccess } from "../../slice/tripSlice";
import MobileBottomTab from "../side-bar-widget-new/index";
import { useClassObserver } from "../../../../utils/hook/useClassObserver";

const BookingEngine: React.FC = () => {
  //This is the useTranslation function destructured to the t variable as per the syntax.
  const { t, i18n } = useTranslation();
  //This variable is used to call the reducer functions in the BookingWidgetSlice.
  const dispatch = useDispatch();
  //This variable defines if the resolution is desktop or not
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const flightStatusSavedTab: any = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget?.tabIndex
  );

  //UseState variables
  //This useState has the main tab and sub-tab values in array format [maintab, subtab].
  const [currentTabIndex, setCurrentTabIndex] = useState<any>(
    flightStatusSavedTab?.length > 0
      ? [flightStatusSavedTab?.[0], flightStatusSavedTab?.[1]]
      : [0, "0"]
  );
  //This is used to control the modal UI of From and To input fields in Book Flight tab
  const [showMobileModal, setShowMobileModal] = useState<boolean>(false);
  //This is used for storing the value of the passenger count data.
  const [passengerCount, setPassengerCount] = useState<PassengerData | null>(
    null
  );
  const [promoCode, setPromoCode] = useState<string>("");
  //This is used for storing the value of class data.
  const [classData, setClassData] = useState<string>("");
  //This is to keep track of the multicity Row count
  const [multiCityCount, setMultiCityCount] = useState<number>(2);

  const [touchStart, setTouchStart] = useState<{ x: number; y: number } | null>(
    null
  );
  const [touchEnd, setTouchEnd] = useState<{ x: number; y: number } | null>(
    null
  );

  //This is used to store the formatted Search Flight Data for the Card section
  const [formattedSearchFlightData, setFormattedSearchFlightData] =
    useState<any>([]);
  const [formattedSearchFromAirportData, setFormattedSearchFromAirportData] =
    useState<any>([]);
  const [formattedSearchToAirportData, setFormattedSearchToAirportData] =
    useState<any>([]);
  // Redeem Miles Case
  const [showSignIn, setShowSignIn] = useState(false);

  const [flightData, setFlightData] = useState<any>({});
  //This is used to update the label field in the modal UI based on the user click of From and To Input fields
  const [key, setKey] = useState<string>("");
  const [modalLabelId, setModalLabelId] = useState<string>("");

  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState(3);
  const [containerWidth, setContainerWidth] = useState(1200);

  const [redeemMilesState, setRedeemMilesState] = useState(false);

  //This is to show the more services menu in small view
  const [showMoreServices, setShowMoreServices] = useState<boolean>(false);
  //This is to store cached data
  const [moreServices, setMoreServices] = useState<any>({});
  //This is to store isLoading value
  const [isLoadingmoreServices, setisLoadingmoreServices] =
    useState<boolean>(true);
  //To handle Focus for Return and One-way
  const [focused, setFocused] = useState({
    from: false,
    to: false,
    date: false,
    pax: false,
  });

  //These are the values of the sub-tabs in the Book Flight section.
  const bookFlightTabs: any[] = [
    { value: BookFlightTab.Return, label: t("label_book_widget_return") },
    { value: BookFlightTab.OneWay, label: t("label_book_widget_one_way") },
    {
      value: BookFlightTab.MultiCity,
      label: t("label_book_widget_multi_city"),
    },
  ];
  //This is used to store the selected Tab in the Book Flights section with initial value as Return.
  const [selectedItem, setSelectedItem] = useState<string>(
    BookFlightTab.Return
  );

  //UseSelector Variables
  //This is used to get the entire booking widget Redux state
  const bookingWidgetData: any = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget
  );

  //This is used to get the airport list data from the redux store
  const airportData: AirportData | null = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.airportData
  );
  //This is used to get the airport selected field data from the Booking widget.
  const airportFieldData = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.fieldData
  );
  // This is used to get the more services data from the redux store
  const moreServicesState = useSelector(
    (state: RootState) => state?.osciReducer?.moreServices.moreServicesDetails
  );

  //Getting the cached data from sessionStorage
  const cachedMoreServicesData = sessionStorage.getItem("moreServices");
  // This is used to get the loading state for more services
  const moreServicesLoading = useSelector(
    (state: RootState) => state?.osciReducer?.moreServices.isLoading
  );

  const profileDetails = useSelector((state: RootState) => state?.osciReducer?.profile?.profileData);
  const nomineeDetails = useSelector((state: RootState) => state?.osciReducer?.nominee?.nomineeData);
  const flightInfo = useSelector((state: RootState) => state?.osciReducer?.flightInfo?.flightInfoArr);
  //API URL for More Services
  const MORE_SERVICES_API_URL = config.TEAMSITE_MORE_SERVICES_API;
  const location_country = window.location.href.split("/")[3];
  const location = location_country.split("-")[0];

  //UseEffect Hooks

  const isBWFieldExist = useClassObserver("booking-widget-overlay");

  useEffect(() => {
    if (isDesktopView) {
      if (isBWFieldExist) {
        document.documentElement.style.setProperty(
          "--everymundo-z-index",
          "-1"
        );
      } else {
        document.documentElement.style.setProperty("--everymundo-z-index", "1");
      }
    }
  }, [isBWFieldExist, isDesktopView]);

  //To call the Nominee API
  useEffect(() => {
    if (profileDetails?.memberID && redeemMilesState) {
      dispatch(fetchNomineeRequest(profileDetails?.memberID));
    }
  }, [profileDetails, redeemMilesState]);

  useEffect(() => {
    if (Object.keys(profileDetails)?.length > 0 && flightInfo?.length > 0) {
      setRedeemMilesState(true);
    }
  }, [profileDetails, flightInfo]);

  // useEffect(()=> {
  //   if(flightStatusSavedTab?.length > 0){
  //     setCurrentTabIndex([flightStatusSavedTab?.[0], flightStatusSavedTab?.[1]]);
  //   }
  // }, [flightStatusSavedTab])

  useEffect(() => {
    if (document.getElementById("bookingWidgetStandalone")) {
      if (location_country) {
        i18n.changeLanguage(location_country);
      }
    }
  }, [document.getElementById("bookingWidgetStandalone"), location_country]);

  //This is called to get the Airport Details from the Airport list API and the Recent Search data from localstorage
  useEffect(() => {
    if (location && location_country) {
      dispatch(airportDataRequest(location));
      i18n.changeLanguage(location_country);
      //Setting the recent search cards based on local storage if there is value for recentSearch key in Local storage.
      const getRecentSearchData = () => {
        //Getting the recent search card data from the local storage on first load
        let recentData = getLocalStorageData("recentSearch");
        if (recentData) {
          let formatData = JSON.parse(recentData);
          setFormattedSearchFlightData(formatData);
          //This is to adjust the swap arrow position when the recent search card has value or not.
          if (formatData.length !== 0)
            //This is needed to adjust the position of the arrow icon when there are recent searches
            document.documentElement.style.setProperty(
              "--mobileSwapAlignment",
              "21.5%"
            );
          //This is needed to adjust the position of the arrow icon when there are no recent searches
          else
            document.documentElement.style.setProperty(
              "--mobileSwapAlignment",
              "28%"
            );
        } else {
          setFormattedSearchFlightData([]);
          document.documentElement.style.setProperty(
            "--mobileSwapAlignment",
            "28%"
          );
        }
      };
      //From Airport Data used in the recent Airports of From Dropdown based on user's searches
      const getRecentFromAirportData = () => {
        let recentData = getLocalStorageData("recentFromAirport");
        if (recentData) {
          let formatData = JSON.parse(recentData);
          setFormattedSearchFromAirportData(formatData);
        } else {
          setFormattedSearchFromAirportData([]);
        }
      };

      //To Airport Data used in the recent Airports of To Dropdown based on user's searches
      const getRecentToAirportData = () => {
        let recentData = getLocalStorageData("recentToAirport");
        if (recentData) {
          let formatData = JSON.parse(recentData);
          setFormattedSearchToAirportData(formatData);
        } else {
          setFormattedSearchToAirportData([]);
        }
      };
      getRecentSearchData();
      getRecentFromAirportData();
      getRecentToAirportData();
    }
  }, [dispatch, location, location_country]);

  // *This is for More Services Tab functionality* //
  useEffect(() => {
    if (cachedMoreServicesData === null) {
      if (currentTabIndex[0] === "4" || showMoreServices) {
        dispatch({
          type: config.FETCH_DATA_CONSTANT,
          url: MORE_SERVICES_API_URL,
          isTeamsite: true,
          successAction: getMoreServicesDataSuccess,
          errorAction: getMoreServicesDataFailure,
        });
        setMoreServices(moreServicesState);
        setisLoadingmoreServices(moreServicesLoading);
      }
    } else {
      setMoreServices(JSON.parse(cachedMoreServicesData));
      setisLoadingmoreServices(false);
    }
  }, [dispatch, currentTabIndex, showMoreServices, cachedMoreServicesData]);

  //This is to handle the more services tab in mobile view, onClick should open the offcanvas
  const handleOpenMoreServicesMenu = () => {
    setShowMoreServices(true);
  };
  const handleCloseMoreServicesMenu = () => {
    setShowMoreServices(false);
  };

  //Booking Widget Tab Selection Functions
  //This is to select the inner tab and main tab value based on user selection.  Here we are resetting the field values in the case of desktop view and also modalAirportData in the case of mobile view.
  //The focus value is also resetted and also the selected recent search card data is emptied.
  const handleTabSelect = (selectedTab: number) => {
    setCurrentTabIndex([currentTabIndex[0], selectedTab]);
    dispatch(updateSelectedCardDataMobile({ cardData: null }));
    setFocused({ from: false, to: false, date: false, pax: false });
  };
  //This is to select the main tab value and make the default inner-tab value to be first tab always.
  const handleMainTabSelect = (selectedTab: any) => {
    //This ensures that the sub-tab index value is initially zero always.
    setCurrentTabIndex([selectedTab, 0]);
    // if (selectedTab === "1" || "2") {
    //   const MANAGE_BOOKING_API_URL = config.TEAMSITE_UPCOMING_TRIPS_API;
    //   dispatch({
    //     type: config.FETCH_DATA_CONSTANT,
    //     url: MANAGE_BOOKING_API_URL,
    //     isTeamsite: false,
    //     successAction: getTripDataSuccess,
    //     errorAction: getTripDataFailure,
    //   });
    // }
  };

  //This function handles the onChange event for all kinds of inputs present in the booking widget like Autosuggest, Dropdown, Select, Calender etc.
  const handleChangeInput = (newValue: any, label: any) => {
    //This conversion is used to convert the labels send from onChange function to lowerCamelCase format.
    const fieldLabel = label
      .replace(/[^\w\s-]/g, "")
      .split(/[\s-]+/)
      .map((word: any, index: any) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join("");
    dispatch(updateFieldData({ fieldLabel, newValue }));
  };

  //Getting Passenger and Class Data based on user selection
  //This is called when the confirm button in the Passenger Class component is clicked.
  const handlePassengerCount = (counts: any) => {
    setPassengerCount(counts);
    dispatch(updateFieldData({ fieldLabel: "passengers", newValue: counts }));
  };

  //This is called on selecting the radio button in the Passenger Class component.
  const handleClassData = (data: any) => {
    setClassData(data);
    dispatch(updateFieldData({ fieldLabel: "class", newValue: data }));
  };

  const handlePromoData = (data: any) => {
    dispatch(updateFieldData({ fieldLabel: "promotionCode", newValue: data }));
  };

  //Multicity Functions
  //This is to set the conditions for the MultiCity based on user interaction
  const handleUpdateMultiCityData = (newCount: number, operation: string) => {
    switch (operation) {
      case "increment":
        setMultiCityCount((prevCount) => prevCount + 1);
        break;
      case "decrement":
        setMultiCityCount((prevCount) => prevCount - 1);
        break;
      default:
        break;
    }
  };

  //This function allows user to open the amadeus redirected url to a different tab.
  const handleAmadeusRedirection = (url: string) => {
    window.open(url, "_blank");
  };

  //Booking Widget Tab Search Functions
  //This function is called when the user clicks on Search flight in the Book Flights tab for Return and One way tabs.
  const handleSearchFlights = () => {
    //This allows to get the config cookie value from cookie.
    const language: any = getCookie("config");
    let promoCodeQueryParams = "";
    const bookFlightUrl =
      process.env.REACT_APP_TG_AMADEUS_URL + config.BOOKING_API;

    let cabinClass = "";

    //Based on the classData useState value, we are assigning the cabinClass value.
    switch (classData) {
      case t("label_business_and_first"):
        cabinClass = CabinClass.Business;
        break;
      case t("label_economy_and_economy_plus"):
        cabinClass = CabinClass.Economy;
        break;
      default:
        break;
    }
    if (
      classData === "" &&
      airportFieldData?.class &&
      ((isDesktopView && bookingWidgetData?.selectedRecentSearchData) ||
        (!isDesktopView && bookingWidgetData.selectedRecentSearchDataMobile))
    ) {
      switch (airportFieldData?.class) {
        case t("label_business_and_first"):
          cabinClass = CabinClass.Business;
          break;
        case t("label_economy_and_economy_plus"):
          cabinClass = CabinClass.Economy;
          break;
        default:
          break;
      }
    }
    let passengerCount = 0;
    //This is to get the image data for recent search card data based on destination airportcode.
    let imageData =
      process.env.REACT_APP_TG_IMAGE_SEARCH_URL +
      airportFieldData?.to.airportCode +
      ".jpg";

    const languageCode = language?.languageCode?.toLowerCase();
    let isRoundTrip: boolean = true;
    //RoundTrip validation for mobile view
    if (!isDesktopView && currentTabIndex[1] !== "0") isRoundTrip = false;
    //RoundTrip validation for desktop view
    if (isDesktopView && selectedItem !== BookFlightTab.Return) {
      isRoundTrip = false;
    }

    let bookFlightQueryParams = "";

    let searchedFlightData: any;
    //Verify if the youth scenario is active or not to change the query params as required.
    let verifyYouthField = false;
    //This is to validate youth field scenario
    if (airportFieldData?.from?.countryCode === "GB") {
      verifyYouthField = true;
    }
    if (airportFieldData?.passengers) {
      switch (verifyYouthField) {
        case true:
          passengerCount =
            airportFieldData?.passengers.adult +
            airportFieldData?.passengers.youth +
            airportFieldData?.passengers.child +
            airportFieldData?.passengers.infant;
          break;
        case false:
          passengerCount =
            airportFieldData?.passengers.adult +
            airportFieldData?.passengers.child +
            airportFieldData?.passengers.infant;
          break;
        default:
          break;
      }
    }

    if (isRoundTrip) {
      searchedFlightData = {
        from: flightData[0]?.from,
        to: flightData[0]?.to,
        depart: airportFieldData?.departReturn[0],
        return: airportFieldData?.departReturn[1],
        type: BookFlightTabSections.RoundTrip,
        class: cabinClass,
        count: passengerCount,
        url: imageData,
        passengers: airportFieldData?.passengers,
        id: 10 - formattedSearchFlightData.length,
      };

      bookFlightQueryParams = `a_airport=${
        flightData[0]?.to?.airportCode
      }&r_Date=${
        airportFieldData?.departReturn[1]
      }&cabin_class=${cabinClass}&d_date=${
        airportFieldData?.departReturn[0]
      }&d_airport=${
        flightData[0]?.from?.airportCode
      }&lang=${languageCode}&n_adult=${
        airportFieldData?.passengers.adult
      }&n_child=${airportFieldData?.passengers?.child}&n_infant=${
        airportFieldData?.passengers?.infant
      }&trip_type=R${
        verifyYouthField
          ? `&n_youth=${airportFieldData?.passengers?.youth}`
          : ""
      }`;
      promoCodeQueryParams = `a_airport=${
        flightData[0]?.to?.airportCode
      }&r_Date=${
        airportFieldData?.departReturn[1]
      }&cabin_class=${cabinClass}&d_date=${
        airportFieldData?.departReturn[0]
      }&lang=${languageCode}&n_adult=${
        airportFieldData?.passengers.adult
      }&n_child=${airportFieldData?.passengers?.child}&n_infant=${
        airportFieldData?.passengers?.infant
      }&trip_type=R${
        verifyYouthField
          ? `&n_youth=${airportFieldData?.passengers?.youth}`
          : ""
      }&d_airport=${
        flightData[0]?.from?.airportCode
      }&promo_code=${airportFieldData?.promotionCode.trim()}`;
    } else {
      searchedFlightData = {
        from: flightData[0]?.from,
        to: flightData[0]?.to,
        depart: airportFieldData?.depart,
        return: "",
        type: BookFlightTabSections.OneWay,
        class: cabinClass,
        count: passengerCount,
        url: imageData,
        passengers: airportFieldData?.passengers,
        id: 10 - formattedSearchFlightData.length,
      };
      bookFlightQueryParams = `a_airport=${
        flightData[0]?.to?.airportCode
      }&cabin_class=${cabinClass}&d_date=${
        airportFieldData?.depart
      }&d_airport=${
        flightData[0]?.from?.airportCode
      }&lang=${languageCode}&n_adult=${
        airportFieldData?.passengers?.adult
      }&n_child=${airportFieldData?.passengers?.child}&n_infant=${
        airportFieldData?.passengers?.infant
      }&trip_type=O${
        verifyYouthField
          ? `&n_youth=${airportFieldData?.passengers?.youth}`
          : ""
      }`;
      promoCodeQueryParams = `a_airport=${
        flightData[0]?.to?.airportCode
      }&r_Date=${
        airportFieldData?.departReturn[1]
      }&cabin_class=${cabinClass}&d_date=${
        airportFieldData?.departReturn[0]
      }&lang=${languageCode}&n_adult=${
        airportFieldData?.passengers.adult
      }&n_child=${airportFieldData?.passengers?.child}&n_infant=${
        airportFieldData?.passengers?.infant
      }&trip_type=O${
        verifyYouthField
          ? `&n_youth=${airportFieldData?.passengers?.youth}`
          : ""
      }&d_airport=${
        flightData[0]?.from?.airportCode
      }&promo_code=${airportFieldData?.promotionCode.trim()}`;
    }
    //This is to update the local storage with the users searched data and also handles the case when user searches for the 5th time and beyond.
    if (formattedSearchFlightData.length !== 0) {
      updateRecentSearchState(
        setFormattedSearchFlightData,
        "recentSearch",
        null,
        searchedFlightData,
        false,
        10
      );
    } else {
      setLocalStorageData("recentSearch", [searchedFlightData]);
      setFormattedSearchFlightData([searchedFlightData]);
      document.documentElement.style.setProperty(
        "--mobileSwapAlignment",
        "21.5%"
      );
    }

    //This is to update the From Airport Data
    if (formattedSearchFromAirportData.length !== 0) {
      updateRecentSearchState(
        setFormattedSearchFromAirportData,
        "recentFromAirport",
        BookFlightAirportDropdown.From,
        searchedFlightData,
        true,
        10
      );
    } else {
      setLocalStorageData("recentFromAirport", [searchedFlightData.from]);
      setFormattedSearchFromAirportData([searchedFlightData.from]);
    }

    //This is to update the To Airport Data
    if (formattedSearchToAirportData.length !== 0) {
      updateRecentSearchState(
        setFormattedSearchToAirportData,
        "recentToAirport",
        BookFlightAirportDropdown.To,
        searchedFlightData,
        true,
        10
      );
    } else {
      setLocalStorageData("recentToAirport", [searchedFlightData.to]);
      setFormattedSearchToAirportData([searchedFlightData.to]);
    }

    const amadeusRedirectionUrl =
      bookFlightUrl + "?" + bookFlightQueryParams + "&source=web";
    handleAmadeusRedirection(amadeusRedirectionUrl);
  };

  //Promo code API handling if user has promocode
  const handlePromoAPI = () => {
    //This allows to get the config cookie value from cookie.
    const language: any = getCookie("config");
    let promoCodeQueryParams = "";

    let cabinClass = "";
    //Based on the classData useState value, we are assigning the cabinClass value.
    switch (classData) {
      case "Business & First":
        cabinClass = CabinClass.Business;
        break;
      case "Economy & Economy Plus":
        cabinClass = CabinClass.Economy;
        break;
      default:
        break;
    }
    let passengerCount = 0;
    //This is to get the image data for recent search card data based on destination airportcode.
    let imageData =
      process.env.REACT_APP_TG_IMAGE_SEARCH_URL +
      airportFieldData?.to.airportCode +
      ".jpg";

    const languageCode = language?.languageCode?.toLowerCase();
    let isRoundTrip: boolean = true;
    //RoundTrip validation for mobile view
    if (!isDesktopView && currentTabIndex[1] !== "0") isRoundTrip = false;
    //RoundTrip validation for desktop view
    if (isDesktopView && selectedItem !== BookFlightTab.Return) {
      isRoundTrip = false;
    }

    let searchedFlightData: any;
    //Verify if the youth scenario is active or not to change the query params as required.
    let verifyYouthField = false;
    //This is to validate youth field scenario
    if (airportFieldData?.from?.countryCode === "GB") {
      verifyYouthField = true;
    }
    if (airportFieldData?.passengers) {
      switch (verifyYouthField) {
        case true:
          passengerCount =
            airportFieldData?.passengers.adult +
            airportFieldData?.passengers.youth +
            airportFieldData?.passengers.child +
            airportFieldData?.passengers.infant;
          break;
        case false:
          passengerCount =
            airportFieldData?.passengers.adult +
            airportFieldData?.passengers.child +
            airportFieldData?.passengers.infant;
          break;
        default:
          break;
      }
    }

    if (isRoundTrip) {
      searchedFlightData = {
        from: airportFieldData?.from,
        to: airportFieldData?.to,
        depart: airportFieldData?.departReturn[0],
        return: airportFieldData?.departReturn[1],
        type: BookFlightTabSections.RoundTrip,
        class: cabinClass,
        count: passengerCount,
        url: imageData,
        passengers: airportFieldData?.passengers,
      };

      promoCodeQueryParams = `a_airport=${
        airportFieldData?.to?.airportCode
      }&r_Date=${
        airportFieldData?.departReturn[1]
      }&cabin_class=${cabinClass}&d_date=${
        airportFieldData?.departReturn[0]
      }&lang=${languageCode}&n_adult=${
        airportFieldData?.passengers.adult
      }&n_child=${airportFieldData?.passengers?.child}&n_infant=${
        airportFieldData?.passengers?.infant
      }&trip_type=R${
        verifyYouthField
          ? `&n_youth=${airportFieldData?.passengers?.youth}`
          : ""
      }&d_airport=${
        airportFieldData?.from?.airportCode
      }&promo_code=${airportFieldData?.promotionCode.trim()}`;
    } else {
      searchedFlightData = {
        from: airportFieldData?.from,
        to: airportFieldData?.to,
        depart: airportFieldData?.depart,
        return: "",
        type: BookFlightTabSections.OneWay,
        class: cabinClass,
        count: passengerCount,
        url: imageData,
        passengers: airportFieldData?.passengers,
      };
      promoCodeQueryParams = `a_airport=${
        airportFieldData?.to?.airportCode
      }&r_Date=${
        airportFieldData?.departReturn[1]
      }&cabin_class=${cabinClass}&d_date=${
        airportFieldData?.departReturn[0]
      }&lang=${languageCode}&n_adult=${
        airportFieldData?.passengers.adult
      }&n_child=${airportFieldData?.passengers?.child}&n_infant=${
        airportFieldData?.passengers?.infant
      }&trip_type=O${
        verifyYouthField
          ? `&n_youth=${airportFieldData?.passengers?.youth}`
          : ""
      }&d_airport=${
        airportFieldData?.from?.airportCode
      }&promo_code=${airportFieldData?.promotionCode.trim()}`;
    }
    const PROMO_CODE_URL =
      process.env.REACT_APP_AMADEUS_URL +
      config.PROMO_API +
      "?" +
      promoCodeQueryParams;
    const PROMO_URL = config.PROMOCODE_API;
    // dispatch({
    //   type: config.FETCH_DATA_CONSTANT,
    //   url: PROMO_URL,
    //   isTeamsite: true,
    //   successAction: getPromoCodeSuccess,
    //   errorAction: getPromoCodeFail
    // });

    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: PROMO_CODE_URL,
      isTeamsite: false,
      successAction: getPromoCodeSuccess,
      errorAction: getPromoCodeFail,
    });
  };

  //This is used to handle the airport switch functionality for the mobile UI.
  const handleAirportSwitch = () => {
    //First checking the bookingWidgetData contains any value.
    if (bookingWidgetData) {
      let airportDetails: any[] = bookingWidgetData.mobileModalVisible.map(
        (item: any) => item.airportDetails
      );
      let validAirportDetails = airportDetails.map((item: any) => {
        if (!item) {
          return false;
        } else {
          return true;
        }
      });
      const isSwitchValid = validAirportDetails.every(
        (item: any) => item === true
      );
      //Here, validating each item in the airportDetails of the mobileModalVisible object in the redux state management to ensure both From and To values are selected.
      if (isSwitchValid) {
        let labelFrom = bookingWidgetData.mobileModalVisible[0].label;
        let labelTo = bookingWidgetData.mobileModalVisible[1].label;
        let updatedAirportFromData = {
          visible: false,
          label: labelFrom,
          airportDetails: airportDetails[1],
        };
        let updatedAirportToData = {
          visible: false,
          label: labelTo,
          airportDetails: airportDetails[0],
        };
        //Now, we are updating the From Dropdown with To value and To Dropdown with From value immutably.
        dispatch(
          updateModalAirportData({
            newValue: updatedAirportFromData,
            label: labelFrom,
          })
        );
        dispatch(
          updateModalAirportData({
            newValue: updatedAirportToData,
            label: labelTo,
          })
        );
        //Updating the field data with the updated values so that the correct query gets passed when user clicks on the Search Flight button.
        dispatch(
          updateFieldData({
            fieldLabel: labelFrom,
            newValue: [updatedAirportFromData.airportDetails],
          })
        );
        dispatch(
          updateFieldData({
            fieldLabel: labelTo,
            newValue: [updatedAirportToData.airportDetails],
          })
        );
      }
    }
  };

  //Desktop Book Flight Tab selection
  //This is used to select the sub tab data in the Book Flights section and also resetting of fields is done when the user switches the tab in the desktop view.
  //The selected card data is also reset when switching tabs.
  const handleDesktopTabSelection = (selected: any) => {
    setSelectedItem(selected);
    dispatch(updateSelectedCardData({ cardData: null }));
    setFocused({ from: false, to: false, date: false, pax: false });
  };

  //This is used to display the full Recent Search section in desktop
  const RecentSearchSection = () => {
    return (
      <>
        {formattedSearchFlightData.length !== 0 && (
          <div title={t("label_book_widget_recent_searches")}>
            <p
              className={styles.recentSearchHeading}
              title={t("label_book_widget_recent_searches")}
            >
              {t("label_book_widget_recent_searches")}
            </p>

            <div className={styles.recentSearchContainer}>
              <TGCustomCarousel
                formattedSearchFlightData={formattedSearchFlightData}
                setFormattedSearchFlightData={setFormattedSearchFlightData}
                setSelectedItem={setSelectedItem}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  //This is used to display the full Recent Search section in mobile
  const RecentSearchSectionMobile: React.FC<any> = () => {
    const [carouselIndex, setCarouselIndex] = useState<any>(0);
    const [direction, setDirection] = useState<any>(null);
    const handleSelectCarousel = (selectedIndex: number, e: any) => {
      setCarouselIndex(selectedIndex);
      setDirection(e.direction);
    };
    const reverseSearchData = reverseJsonArray(formattedSearchFlightData);

    //Render the recent search card for mobile view
    const recentSearchCardMobile = (item: any, cardIndex: any) => {
      //This is used to validate the depart date is invalid or valid
      const isDepartDateBeforeNow = validateDepartDate(item?.depart);
      //This function removes the card if the user clicks on the Close button.  The local storage data is also removed when the user removes the card.
      const handleRemoveCardMobile = (
        e: React.MouseEvent<HTMLButtonElement>,
        id: any
      ) => {
        e.stopPropagation();
        let updatedCard = formattedSearchFlightData.filter(
          (x: any, itemIndex: any) => x.id !== id
        );
        if (updatedCard.length === 0) {
          document.documentElement.style.setProperty(
            "--mobileSwapAlignment",
            "28%"
          );
        }
        //This ensures that the active index of the carousel moves to the first position when the last card is removed.
        if (cardIndex >= updatedCard.length) {
          setCarouselIndex(Math.max(updatedCard.length - 1, 0));
        }
        setFormattedSearchFlightData(updatedCard);
        setLocalStorageData("recentSearch", updatedCard);
      };

      //This is called when user selects on the card in recent searches section.
      const handleCardSelectionMobile = (
        e: React.MouseEvent<HTMLDivElement>,
        cardData: any
      ) => {
        e.stopPropagation();
        //This is the tab switching logic in the case of mobile view.
        if (cardData.type === BookFlightTabSections.RoundTrip) {
          setCurrentTabIndex([currentTabIndex[0], "0"]);
        } else if (cardData.type === BookFlightTabSections.OneWay) {
          setCurrentTabIndex([currentTabIndex[0], "1"]);
        } else {
          setCurrentTabIndex([currentTabIndex[0], "2"]);
        }
        dispatch(updateSelectedCardDataMobile({ cardData: cardData }));
      };

      //Image error handling for mobile section
      const handleErrorMobile = (
        event: React.SyntheticEvent<HTMLImageElement, Event>
      ) => {
        event.currentTarget.src = "../assets/recentsearchdefault.png";
      };

      return (
        <>
          {item && (
            <div
              className={`${styles.cardStyle} ${
                item?.type === "Multi-city" && styles.cardStyleMultiCityMobile
              }`}
              onClick={(e) => handleCardSelectionMobile(e, item)}
            >
              {item?.type !== "Multi-city" && (
                <div>
                  <img
                    src={item.url}
                    onError={handleErrorMobile}
                    className={styles.cardSearchImg}
                    alt={`recent search card data ${cardIndex}`}
                  />
                </div>
              )}
              <div>
                {item?.type !== "Multi-city" ? (
                  <>
                    <div>
                      <button
                        onClick={(e) => handleRemoveCardMobile(e, item.id)}
                        aria-label="close button"
                        className={`${
                          item.id < 10
                            ? styles.closeButtonAlignment
                            : styles.lastCloseButton
                        }`}
                      >
                        <TGIcon icon="x-circle" size={""} fillColor={"none"} />
                      </button>
                    </div>
                    <div className={styles["card-airport-details"]}>
                      <span className="me-2">{item?.from?.airportCode}</span>
                      <span className="me-2">
                        <TGIcon icon="plane-icon" size={""} fillColor={""} />
                      </span>
                      <span>{item?.to?.airportCode}</span>
                    </div>
                  </>
                ) : (
                  <div className={styles["airport-name-multicity"]}>
                    <span
                      className={`${styles["multi-city-airport-code-wrapper"]} me-1`}
                    >
                      {item?.from?.airportCode}
                    </span>
                    <button
                      onClick={(e) => handleRemoveCardMobile(e, item?.id)}
                      className={`${styles["close-button-multicity"]} ${styles["buttonStyle"]}`}
                    >
                      <TGIcon icon="x-circle" size={""} fillColor={"none"} />
                    </button>
                  </div>
                )}
                <div>
                  {item?.return !== "" && isDepartDateBeforeNow && (
                    <div>
                      {""} -{" "}
                      {moment(item?.return, "YYYY-MM-DD").format("DD MMM YYYY")}
                    </div>
                  )}
                  {item?.return !== "" && !isDepartDateBeforeNow && (
                    <div>
                      {moment(item?.depart, "YYYY-MM-DD").format("DD MMM YYYY")}{" "}
                      -{" "}
                      {moment(item?.return, "YYYY-MM-DD").format("DD MMM YYYY")}
                    </div>
                  )}
                  {item?.return === "" && isDepartDateBeforeNow && (
                    <div>&nbsp;</div>
                  )}
                  {item?.return === "" && !isDepartDateBeforeNow && (
                    <div>
                      {moment(item?.depart, "YYYY-MM-DD").format("DD MMM YYYY")}
                    </div>
                  )}
                </div>
                <div>{item?.type}</div>
                {item?.class === CabinClass.Economy && item?.count > 1 && (
                  <div>
                    {item?.count} {t("label_book_widget_passengers")} |{" "}
                    {t("label_economy_and_economy_plus")}
                  </div>
                )}
                {item?.class === CabinClass.Economy && item?.count === 1 && (
                  <div>
                    {item?.count} {t("label_passenger")} |{" "}
                    {t("label_economy_and_economy_plus")}
                  </div>
                )}
                {item?.class === CabinClass.Business && item?.count > 1 && (
                  <div>
                    {item?.count} {t("label_book_widget_passengers")} |{" "}
                    {t("label_business_and_first")}
                  </div>
                )}
                {item?.class === CabinClass.Business && item?.count === 1 && (
                  <div>
                    {item?.count} {t("label_passenger")} |{" "}
                    {t("label_business_and_first")}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      );
    };

    return (
      <>
        {formattedSearchFlightData.length !== 0 && (
          <div
            title={t("label_book_widget_recent_searches")}
            className={`mb-4 ${styles["recent-search-section"]}`}
          >
            <p
              className={styles.recentSearchHeading}
              title={t("label_book_widget_recent_searches")}
            >
              {t("label_book_widget_recent_searches")}
            </p>
            <div className={styles.mobileCarouselContainer}>
              {formattedSearchFlightData.length === 1 && (
                <Carousel
                  className={styles.customCarousel}
                  interval={null}
                  indicators={false}
                  controls={false}
                  slide={false}
                  onSelect={handleSelectCarousel}
                  activeIndex={carouselIndex}
                >
                  <CarouselItem>
                    <span className={styles.full}>
                      {recentSearchCardMobile(formattedSearchFlightData[0], 0)}
                    </span>
                  </CarouselItem>
                </Carousel>
              )}
              {formattedSearchFlightData.length > 1 && (
                <Carousel
                  className={styles.customCarousel}
                  interval={null}
                  indicators={false}
                  controls={false}
                  activeIndex={carouselIndex}
                  onSelect={handleSelectCarousel}
                  slide={true}
                  touch
                >
                  {reverseSearchData.map((item: any, index: any) => {
                    return (
                      <CarouselItem key={index + 1}>
                        <div className={`${styles["customCarouselItem"]}`}>
                          {carouselIndex !==
                            formattedSearchFlightData.length - 1 && (
                            <>
                              <span className={styles.normal}>
                                {recentSearchCardMobile(
                                  reverseSearchData[index],
                                  index
                                )}
                              </span>
                              <span className={styles.partial}>
                                {recentSearchCardMobile(
                                  reverseSearchData[index + 1],
                                  index + 1
                                )}
                              </span>
                            </>
                          )}
                          {carouselIndex ===
                            formattedSearchFlightData.length - 1 && (
                            <span className={styles.full}>
                              {recentSearchCardMobile(item, carouselIndex)}
                            </span>
                          )}
                        </div>
                      </CarouselItem>
                    );
                  })}
                </Carousel>
              )}
              <div className={styles["custom-indicators"]}>
                {formattedSearchFlightData.map((item: any, index: any) => (
                  <div
                    key={`dot${index}`}
                    className={`${styles["custom-indicators-dot"]} ${
                      carouselIndex === index ? styles["active"] : ""
                    }`}
                    onClick={(e) => handleSelectCarousel(index, e)}
                  ></div>
                ))}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleSignInPopupClose = (callback?: Function): void => {
    setShowSignIn(false);
    if (Object.keys(profileDetails)?.length === 0) {
      setRedeemMilesState(false);
    }
    if (callback) {
      callback();
    }
  };

  const handleSignInPopupShow = (): void => {
    setShowSignIn(!showSignIn);
  };

  const handleRedeemMilesSelection = (e: any, state: any) => {
    setRedeemMilesState(state);
    if (window?.sessionStorage.getItem("isROPLoggedIn") === "true") {
      setShowSignIn(false);
      if (Object.keys(profileDetails)?.length === 0) {
        dispatch(fetchProfileRequest());
      }
      document.documentElement.style.setProperty("--loading-spinner", "block");
      setTimeout(() => {
        document.documentElement.style.setProperty("--loading-spinner", "none");
      }, [500]);
    } else {
      setShowSignIn(true);
    }
  };

  const handleRedeemToggleInactive = () => {
    setRedeemMilesState(false);
  };

  const handleSuccess = () => {
    dispatch(fetchProfileRequest());
    setRedeemMilesState(true);
  };

  //This is used to render the tab content based on the user selection in the Book Flights tab.
  const renderTabContent = () => {
    switch (selectedItem) {
      case "Return":
        return (
          <>
            <BookFlight
              onPromoCall={handlePromoAPI}
              onPromoCodeInput={handlePromoData}
              getFlightData={updateSelectedFlightData}
              onButtonClick={handleSearchFlights}
              onPassengerCountChange={handlePassengerCount}
              onClassChange={handleClassData}
              onChangeInput={handleChangeInput}
              options={airportData || []}
              recentSearchData={formattedSearchFlightData || []}
              type={BookFlightTab.Return}
              focused={focused}
              setFocused={setFocused}
              currentTabIndex={selectedItem === BookFlightTab.Return && 0}
            />
            {<RecentSearchSection />}
          </>
        );
      case "One-way":
        return (
          <>
            <BookFlight
              onPromoCall={handlePromoAPI}
              onPromoCodeInput={handlePromoData}
              onButtonClick={handleSearchFlights}
              getFlightData={updateSelectedFlightData}
              onPassengerCountChange={handlePassengerCount}
              onClassChange={handleClassData}
              onChangeInput={handleChangeInput}
              options={airportData || []}
              recentSearchData={formattedSearchFlightData || []}
              type={BookFlightTab.OneWay}
              focused={focused}
              setFocused={setFocused}
              currentTabIndex={selectedItem === BookFlightTab.OneWay && 1}
            />
            {<RecentSearchSection />}
          </>
        );
      case "Multi-city":
        return (
          <>
            <MultiCity
              onPassengerCountChange={handlePassengerCount}
              onClassChange={handleClassData}
              onChangeInput={() => {}}
              options={airportData || []}
              onInputClick={() => {}}
              recentSearchData={formattedSearchFlightData || []}
              type="MultiCity"
              handleUpdate={handleUpdateMultiCityData}
              count={multiCityCount}
              setFormattedSearchFlightData={setFormattedSearchFlightData}
            />
            {<RecentSearchSection />}
          </>
        );
      default:
        return null;
    }
  };

  const updateSelectedFlightData = (flightInfo: any) => {
    dispatch(
      updateFieldData({ fieldLabel: "from", newValue: flightInfo[0]?.from })
    );
    dispatch(
      updateFieldData({ fieldLabel: "to", newValue: flightInfo[0]?.to })
    );
    setFlightData(flightInfo);
  };

  return (
    <>
      {isDesktopView && (
        <div className={styles.bookingContainer}>
          <Row
            // noGutters={true}
            className={`global-content-margin ${styles.customRow}  ${styles["main-booking-tab-wrapper"]}`}
          >
            <TGTabs
              variant="pills"
              onSelect={handleMainTabSelect}
              className="tg-tabs-mainTab"
              optionalClassName="tg-tabs-custom-main-tab-container"
              optionalUnderlineClassName="mb-4"
              activeIndex={[currentTabIndex?.[1], currentTabIndex?.[0]]}
            >
              <div
                title={t("label_book_widget_book_flights")}
                className={`${styles.bookFlightsPadding} gap-4`}
                key={0}
              >
                {!redeemMilesState && Number(currentTabIndex?.[0]) === 0 && (
                  <div className={styles.flexContainer}>
                    <div className="gap-3">
                      {bookFlightTabs.map((item: any) => (
                        <button
                          key={item}
                          className={`${styles.item} ${
                            selectedItem === item.value
                              ? `${styles.selected}`
                              : ""
                          }`}
                          onClick={() => handleDesktopTabSelection(item.value)}
                          title={item.value}
                          aria-label={`Book Flight Tab ${item.value}`}
                        >
                          {item.label}
                        </button>
                      ))}
                    </div>
                    <div>
                      <div
                        className={`${styles.buttonContainer}`}
                        title={t("label_book_widget_redeem_miles")}
                      >
                        <TGToggle
                          variant="secondary"
                          buttonExists={false}
                          label={t("label_book_widget_redeem_miles")}
                          onClick={handleRedeemMilesSelection}
                          active={redeemMilesState}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!redeemMilesState && <div>{renderTabContent()}</div>}
                {redeemMilesState && (
                  <RedemptionBookingWidget
                    redeemState={redeemMilesState}
                    handleRedeemInactive={handleRedeemToggleInactive}
                    isDesktopView={true}
                    currentActiveTabIndex={selectedItem}
                    setCurrentActiveTabIndex={setSelectedItem}
                  />
                )}
              </div>
              <div
                title={t("label_book_widget_manage_booking")}
                className={styles.tabContentPadding}
                key={1}
              >
                {currentTabIndex?.[0] === "1" && <ManageBooking />}
              </div>
              <div
                title={t("label_book_widget_check_in")}
                className={styles.tabContentPadding}
                key={2}
              >
                {currentTabIndex?.[0] === "2" && <CheckIn />}
              </div>
              <div
                title={t("label_book_widget_flight_status")}
                className={styles.bookFlightsPadding}
                key={3}
              >
                {currentTabIndex?.[0] === "3" && (
                  <FlightStatus
                    currentTabIndex={parseInt(currentTabIndex[0])}
                  />
                )}
              </div>
            </TGTabs>
          </Row>
        </div>
      )}
      {!isDesktopView && (
        <div className={`${styles.bookingContainer}`}>
          <Row className={`${styles.customRow} px-0  global-content-margin`}>
            {!redeemMilesState && (
              <TGTabs
                variant="underline"
                onSelect={handleTabSelect}
                title={t("label_book_widget_return")}
                className="tg-tabs-subClass-widget"
                optionalClassName="tg-tabs-custom-tab-container"
                activeIndex={currentTabIndex}
              >
                <div title={t("label_book_widget_return")}>
                  <div className={`${styles.buttonContainer}`}>
                    <TGToggle
                      variant="secondary"
                      buttonExists={false}
                      label={t("label_book_widget_redeem_miles")}
                      active={redeemMilesState}
                      onClick={handleRedeemMilesSelection}
                    />
                  </div>
                  {Number(currentTabIndex?.[1]) === 0 && (
                    <>
                      <BookFlight
                        onPromoCall={handlePromoAPI}
                        onButtonClick={handleSearchFlights}
                        onPromoCodeInput={handlePromoData}
                        getFlightData={updateSelectedFlightData}
                        onPassengerCountChange={handlePassengerCount}
                        onClassChange={handleClassData}
                        onChangeInput={handleChangeInput}
                        onReverseArrowClick={handleAirportSwitch}
                        options={airportData || []}
                        type={BookFlightTab.Return}
                        tabIndex={currentTabIndex[1]}
                        focused={focused}
                        setFocused={setFocused}
                        currentTabIndex={Number(currentTabIndex[1])}
                        activeCurrentIndex={currentTabIndex}
                      />
                      <RecentSearchSectionMobile />
                    </>
                  )}
                </div>

                <div title={t("label_book_widget_one_way")}>
                  <div className={`${styles.buttonContainer}`}>
                    <TGToggle
                      variant="secondary"
                      buttonExists={false}
                      label={t("label_book_widget_redeem_miles")}
                      active={redeemMilesState}
                      onClick={handleRedeemMilesSelection}
                    />
                  </div>
                  {currentTabIndex?.[1] === "1" && (
                    <>
                      <BookFlight
                        onPromoCall={handlePromoAPI}
                        onButtonClick={handleSearchFlights}
                        onPromoCodeInput={handlePromoData}
                        getFlightData={updateSelectedFlightData}
                        onPassengerCountChange={handlePassengerCount}
                        onClassChange={handleClassData}
                        onChangeInput={handleChangeInput}
                        onReverseArrowClick={handleAirportSwitch}
                        options={airportData || []}
                        type={BookFlightTab.OneWay}
                        tabIndex={currentTabIndex[1]}
                        focused={focused}
                        setFocused={setFocused}
                        currentTabIndex={Number(currentTabIndex[1])}
                        activeCurrentIndex={currentTabIndex}
                      />
                      <RecentSearchSectionMobile />
                    </>
                  )}
                </div>

                <div title={t("label_book_widget_multi_city")}>
                  <div className={`${styles.buttonContainer}`}>
                    <TGToggle
                      variant="secondary"
                      buttonExists={false}
                      label={t("label_book_widget_redeem_miles")}
                      active={redeemMilesState}
                      onClick={handleRedeemMilesSelection}
                    />
                  </div>
                  {currentTabIndex?.[1] === "2" && (
                    <>
                      <MultiCity
                        onInputClick={() => {}}
                        onPassengerCountChange={handlePassengerCount}
                        onClassChange={handleClassData}
                        onChangeInput={() => {}}
                        options={airportData || []}
                        type={"MultiCity"}
                        setFormattedSearchFlightData={
                          setFormattedSearchFlightData
                        }
                      />
                      <RecentSearchSectionMobile />
                    </>
                  )}
                </div>
              </TGTabs>
            )}
            {redeemMilesState && (
              <RedemptionBookingWidget
                redeemState={redeemMilesState}
                handleRedeemInactive={handleRedeemToggleInactive}
                isDesktopView={false}
                currentActiveTabIndex={currentTabIndex[1]}
                setCurrentActiveTabIndex={setCurrentTabIndex}
              />
            )}
          </Row>
          <MobileBottomTab />
        </div>
      )}
      {showSignIn && (
        <LoginWidget
          onHidePanel={handleSignInPopupClose}
          loginName={() => {}}
          handlePanelShow={handleSignInPopupShow}
          isTabbed={true}
          handleSuccess={handleSuccess}
        />
      )}
    </>
  );
};

export default BookingEngine;
